import { yupResolver } from '@hookform/resolvers/yup'
import log from 'loglevel'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { AuthErrorCodes } from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import Block from 'components/block'
import { ButtonWrapper } from 'components/layout'
import { PageTitle } from 'components/typography'
import { Text } from 'components/typography'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole } from 'schema/user'
import AuthApi from 'apis/auth'

interface LoginProp {
  email: string
}

const OwnerLogin = () => {
  const { currentUser, claims } = useAuthProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const schema = yup.object({
    email: yup
      .string()
      .required(ErrorMsg.REQUIRED)
      .email(ErrorMsg.INVALID_EMAIL),
  })

  const form = useForm<LoginProp>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (currentUser && claims && claims?.role === UserRole.OWNER) {
      navigate(ROUTING_PATH.B0201)
    }
  }, [claims, currentUser, navigate])

  const handleSubmit = useCallback(
    async (data: LoginProp) => {
      try {
        setLoading(true)
        await AuthApi.sendSignInLink(data.email)
          .then(() => {
            window.localStorage.setItem('emailForSignIn', data.email)
            navigate(ROUTING_PATH.OwnerSignInLink)
          })
          .catch((e) => {
            switch (e.code) {
              case AuthErrorCodes.USER_DELETED:
                form.setError('email', {
                  type: 'custom',
                  message: ErrorMsg.NOT_FOUND_EMAIL,
                })
                break
              default:
                throw e
            }
          })
      } catch (e: any) {
        log.error(e)
      }

      setLoading(false)
    },
    [form, navigate]
  )

  return (
    <Page title="ログイン" loading={loading} type="guest">
      <PageTitle>売主用ログインページ</PageTitle>
      <Block style={{ marginBottom: '10px' }}>
        <Form form={form} onSubmit={handleSubmit}>
          <FormControl label="Eメールアドレス" name="email">
            <Input
              type="email"
              placeholder="abc@example.com"
              maxLength={200}
              aria-label="Eメールアドレス"
            />
          </FormControl>
          <ButtonWrapper>
            <Button>ログインURLを送信する</Button>
          </ButtonWrapper>
        </Form>
      </Block>
      <Block>
        <Text size="sm">
          こちらは、Terassに所属するエージェントと媒介契約を結んだ売主様向けのログインページです。
        </Text>
        <Text size="sm">
          ログインには担当エージェントにお伝えしたメールアドレスをご利用ください。
        </Text>
        <Text size="sm">
          問い合わせは、お手数をおかけしますが、担当エージェントにお伝えください。
        </Text>
      </Block>
    </Page>
  )
}

export default OwnerLogin
