import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import { Contract, Feature, PoratalSiteInfo } from 'schema/contract'
import theme from 'styles/theme'
import { formatedDate } from 'utils'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
} from '../../../pages/contract/logic/graph'
import { getDay, parse } from 'date-fns'

const AtHomeFavoriteGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const graphData = useMemo(() => {
    if (!contract?.athome?.favoriteCount) {
      return []
    }

    // favoriteCount は当日時点のお気に入り数が表示される（差分でない）
    // SP版は1週間のデータの合計値を表示する仕組みになっているため、合計値が大きくなってしまう
    let favoriteCount: PoratalSiteInfo[] = contract?.athome?.favoriteCount || []
    favoriteCount = favoriteCount.map((data) => {
      // PCの場合はそのまま返す
      if (breakpoint === 'pc') {
        return data
      }
      const formatDate = formatedDate(data.date, 'yyyy/MM/dd')
      const parsedDate = parse(formatDate, 'yyyy/MM/dd', new Date())
      // SPの場合は月曜日のデータのみを返す
      const day = getDay(parsedDate)
      if (day !== 1) {
        return {
          ...data,
          quantity: 0,
        }
      } else {
        return data
      }
    })

    return createBarGraphData(favoriteCount, breakpoint)
  }, [breakpoint, contract?.athome?.favoriteCount])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: '',
        data: graphData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={{
          ...BAR_GRAPH_OPTIONS,
          plugins: {
            ...BAR_GRAPH_OPTIONS.plugins,
            legend: {
              ...BAR_GRAPH_OPTIONS.plugins?.legend,
              display: false,
            },
          },
        }}
        plugins={GRAPH_PLUGINS('回数')}
      />
    </>
  )
}

export default AtHomeFavoriteGraph
